<template>
  <section>
    <BaseInfoItem v-if="info" v-bind="info" />
    <div v-if="list" class="xa-cell xa-txt-secondary" style="padding:8px 15px;">
      {{ list.title }}
    </div>
    <TemplateDiyList :coms="coms" v-on="{ action: onAction }" />
  </section>
</template>
<script>
import BaseInfoItem from '@/components/diylist/BaseInfoItem'

import diyList from '@/mixins/diyList'
let curPath = ''
let coms = []
export default {
  mixins: [diyList],
  config: {
    fetchDataFn: 'initView',
    watchRoute: true
  },
  components: { BaseInfoItem },
  data() {
    return {
      info: null,
      list: {
        title: ''
      }
    }
  },
  methods: {
    onAction(btn) {
      if (btn.href || btn.ajax) this.$_handAction(btn)
    },
    async fetchList({ src, params }) {
      const result = await this.$_request({
        url: src,
        data: params
      })
      let { body: coms, title } = result
      this.coms = this.$_convertComs(coms)
      this.list.title = title
    },
    async initView() {
      const { url, data } = this.$_extractRequestInfoFormRoute()
      const result = await this.$_request({
        url: url || '/mock/diy-list/ensure-detail-list',
        data
      })
      this.info = result.info // 详情页面基本信息
      this.list = result.list // 详情页面使用到的列表
      if (this.list) {
        await this.fetchList(this.list)
      }
      return result
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.fullPath === curPath) {
        vm.coms = coms || []
      }
      curPath = ''
    })
  },
  beforeRouteLeave(to, from, next) {
    curPath = from.fullPath
    coms = JSON.parse(JSON.stringify(this.coms))
    next()
  }
}
</script>
